.plans-page {
    .personal-color {
        color: #E2A208 !important;
    }
    .backup-color {
        color: #25A7A7 !important;
    }
    .archiver-color {
        color: #0075E1 !important;
    }
    .nfp_backup-color {
        color: #25A7A7 !important;
    }

    .nfp_archiver-color {
        color: #0075E1 !important;
    }
    .nfr-color {
        color: #5F6F89 !important;
    }
    .qbo_sku-color {
        color: $red-30;
    }
    .datatable-title {
        font-weight: 600;
    }

    .tabulator-row {
        &.tabulator-selectable:hover {
          cursor: default;
        }

        &.tabulator-selected:hover {
          cursor: default;
        }
    }

    .solid-calc {
        font-size: 17px;
    }

}

.modal-list-organizations {
    .modal-header {
        i {
            font-size: 2.5em;
            margin: 0.25em;
        }
        .wrapper-header {

            .truncate-ellipsis {
                display: block;
            }
        }
    }

    .personal-color {
        color: #E2A208 !important;
    }
    .backup-color {
        color: #25A7A7 !important;
    }
    .archiver-color {
        color: #0075E1 !important;
    }
    .nfp_backup-color {
        color: #25A7A7 !important;
    }

    .nfp_archiver-color {
        color: #0075E1 !important;
    }
    .nfr-color {
        color: #5F6F89 !important;
    }
    .datatable-title {
        font-weight: 600;
    }
    .qbo_sku-color {
        color: $red-30;
        font-size: 2rem;
    }

    .tabulator-header {
        border-bottom: 2px solid #F2F4F6;
        box-shadow: none;
    }

    .tabulator-row {

        .tabulator-cell {
            cursor: pointer;
            color: #0075E1;
        }
    }

    .tabulator-footer {
        border: 0;
        p {
            margin-top: 0.5em;
            margin-bottom: -0.5em
        }
    }

     //footer element
    .tabulator-footer {
        padding: 1rem;
        text-align: right;
        font-weight: bold;
        white-space: nowrap;
        user-select: none;

        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;

        .tabulator-calcs-holder {
            box-sizing: border-box;
            width: calc(100% + 20px);
            margin: -5px -10px 5px -10px;
            text-align: left;
            background: lighten($backgroundColor, 5%) !important;

            .tabulator-row {
                background: lighten($backgroundColor, 5%) !important;

                .tabulator-col-resize-handle {
                    display: none;
                }
            }

            border-bottom: 1px solid $rowBorderColor;
            border-top: 1px solid $rowBorderColor;

            overflow: hidden;

            &:only-child {
                margin-bottom: -5px;
                border-bottom: none;
            }
        }

        //pagination container element
        .tabulator-paginator {
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
        }

        //page size select element
        .tabulator-page-size {
            display: inline-block;

            margin: 0 1rem 0 5px;
            padding: 2px 5px;

            border: 1px solid $footerBorderColor;
            border-radius: 3px;
        }

        //pagination button
        .tabulator-page {
            display: inline-block;

            margin: 5px 0 0;
            padding: 5px 12px;

            border: none;
            border-radius: $border-radius;

            background-color: transparent;

            &[data-page="first"] {
            }

            &[data-page="last"] {
            }

            &.active {
                background-color: $pagination-active-bg;
                color: $footerActiveColor;
            }

            &:disabled {
                visibility: hidden;
                border-color: $pagination-disabled-border-color;
                background: $pagination-disabled-bg;
                color: $pagination-disabled-color;
            }

            &:not(:disabled) {
                &:hover {
                    cursor: pointer;
                    border-color: $pagination-hover-border-color;
                    background: $pagination-hover-bg;
                    color: $pagination-hover-color;
                }
            }
        }
    }
}