//  ================================================================================================
//  File Name: variables.scss
//  Description: Custom overrides of Bootstrap variables
//  ----------------------------------------------------------------------------------------------
//  Item Name: Stack - Responsive Admin Theme
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
//  ================================================================================================

// Color system

$white: #fff;
$gray-100: #E9EBED; // $gray-lightest
$gray-200: #8F99A3; // $gray-lighter
$gray-300: #626e82; // $gray-light
$gray-400: #6f7274;
$gray-500: #adb5bd;
$gray-600: #404e67; // $gray
$gray-700: #4e5154;
$gray-800: #1b2942; // $gray-dark
$gray-900: #2a2e30;
$black: #000;

// Primary color gradient
$primary-1:  #f3f4f5;
$primary-2:  #e9ebed;
$primary-3:  #c7ccd1;
$primary-4:  #abb3ba;
$primary-5:  #8f99a3;
$primary-6:  #77838f;
$primary-7:  #636d78;
$primary-8:  #4f5760;
$primary-9:  #3b4248;
$primary-10: #272c30;
$primary-11: #7D858D;

// Secondary color gradient
$secondary-1:  #D3EFFB;
$secondary-2:  #A7DFF7;
$secondary-3:  #7ACFF4;
$secondary-4:  #4EBFF0;
$secondary-5:  #22AFEC;
$secondary-6:  #139EDA;
$secondary-7:  #1087BA;
$secondary-8:  #0D709B;
$secondary-9:  #0B5A7C;
$secondary-10: #08445D;

// Danger color gradient
$danger-1:  #f6dfe0;
$danger-3:  #fb888e;
$danger-5:  #dd252f;
$danger-6:  #b9262e;
$danger-8:  #881f25;
$danger-10: #612326;

// Warning color gradient
$warning-1:  #ffdec1;
$warning-3:  #ffda82;
$warning-5:  #ffc844;
$warning-6:  #e7a300;
$warning-8:  #b98200;
$warning-10: #8a6200;

// Success color gradient
$success-1:  #caefd2;
$success-3:  #94dfa5;
$success-5:  #36b252;
$success-6:  #2e9946;
$success-8:  #1f662f;
$success-10: #174c23;

// Blue color gradient
$blue-80: #072A4A;
$blue-70: #09457B;
$blue-60: #065DAC;
$blue-50: #0075E1;
$blue-40: #399DF9;
$blue-30: #8CC4F8;
$blue-20: #E3F0FC;

$red-80: #4A0711;
$red-70: #84101F;
$red-60: #BA1C31;
$red-50: #DD3C51;
$red-40: #EA7181;
$red-30: #F4A9B3;
$red-20: #FCE3E6;

$yellow-80: #4A3707;
$yellow-70: #986E0B;
$yellow-60: #E2A208;
$yellow-50: #FFC333;
$yellow-40: #FBD270;
$yellow-30: #FAE3AE;
$yellow-20: #FCF5E3;

$green-80: #074A16;
$green-70: #116925;
$green-60: #1A8933;
$green-50: #25A742;
$green-40: #5EC977;
$green-30: #A8DCB4;
$green-20: #E3FCE9;

$blue: $blue-20; //$info
$red: $red-20; //$danger
$orange: $yellow-60; //$warning
$green: $green-20; //$success
$cyan: $blue-20; //$primary

$primary: $blue-50;
$info: $blue-50;
$warning: $yellow-60;
$secondary: $primary-9;
$success: $green-50;
$danger: $red-60;
$light: $gray-100;
$dark: $gray-800;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":      $primary,
    "secondary":    $secondary,
    "success":      $success,
    "info":         $info,
    "warning":      $warning,
    "danger":       $danger,
    "light":        $light,
    "dark":         $dark,
    "light-blue":   $blue-20,
    "light-yellow": $yellow-20,
    "light-red":    $red-20,
    "light-green":  $green-20,
    "light-grey":   $primary-2,
    "grey":         $primary-11, 
  ),
  $theme-colors
);

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 8;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0,
      25: ($spacer * .25),
      50: ($spacer * .5),
      75: ($spacer * .75),
      1: ($spacer),
      2: ($spacer * 1.5),
      3: ($spacer * 3),
      4: ($spacer * 3.5),
      5: ($spacer * 4)),
   $spacers);


// Body
//
// Settings for the `<body>` element.

$body-bg: #f5f7fa;
$body-color: $primary-10;

// Links
//
// Style anchor elements.

$link-color: darken($primary, 5%);
$link-hover-color: darken($link-color, 5%);
$link-hover-decoration: none;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.25;
$line-height-sm: 1;
$border-width: 1px;
$border-color: $gray-100;
$border-radius: .25rem;
$border-radius-lg: 0.27rem;
$border-radius-sm: 0.21rem;

// Typography

//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Inter', sans-serif;
$font-family-monospace: $font-family-sans-serif;
$font-family-icon: FontAwesome;

// stylelint-enable value-keyword-case
$font-size-base: 1rem;
$font-size-sm: ($font-size-base * 0.875);
$font-size-lg: ($font-size-base * 1.25);

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.428;
$h3-font-size: $font-size-base * 1.285;
$h4-font-size: $font-size-base * 1.142;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * .857;

$headings-margin-bottom: ($spacer * .8);
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: $font-weight-bold;

$headings-color: inherit;

$display1-weight: 400;
$display2-weight: 400;
$display3-weight: 400;
$display4-weight: 400;

$lead-font-size: ($font-size-base * 1.25);
$lead-font-weight: 400;

$text-muted: $gray-200;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-bg: $white;
$table-accent-bg: rgba(245, 247, 250, 0.5);

$table-hover-bg: rgba(245, 247, 250, 0.5);
$table-border-color: $primary-1;
$table-dark-bg: $gray-900;
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $body-bg;

$table-cell-padding: 0.5rem;
$table-cell-padding-sm: .3rem;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.25rem;

$input-btn-line-height-sm: $line-height-sm;
$input-btn-line-height: $line-height-lg;

$input-btn-focus-width: 0.2rem !default;

$input-btn-focus-color: rgba($primary, 0.25);
$input-btn-focus-box-shadow: none;

$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 1.25rem;
$input-btn-line-height-lg: $line-height-lg;
$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-transition: background-color 0.15s ease-in-out,
border-color 0.15s ease-in-out,
box-shadow 0.15s ease-in-out;

$btn-padding-y: .71rem;
$btn-padding-x: 1.14rem;
$btn-padding-y-sm: .5rem;
$btn-padding-x-sm: 1rem;
$btn-padding-y-lg: .85rem;
$btn-padding-x-lg: 1.7rem;

$btn-font-size: 1rem;
$btn-font-size-sm: 1rem;
$btn-font-size-lg: 1rem;

// Forms
$input-disabled-bg: #ECEFF1;
$input-border-color: $primary-2;

$input-focus-border-color: $primary;
$input-placeholder-color: $primary-5;

$input-height-border: $input-btn-border-width * 2;

$input-height-inner: ($font-size-base * $input-btn-line-height)+($input-btn-padding-y * 2);

$input-height: calc(#{$input-height-inner} + #{$input-height-border});

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm)+($input-btn-padding-y-sm * 2);
$input-height-sm: ($font-size-sm * $line-height-sm) + ($input-btn-padding-y-sm * 5.5);

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg)+($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});

$input-transition: border-color ease-in-out 0.15s,
box-shadow ease-in-out 0.15s;
$form-check-input-margin-y: 0.25rem;
$input-group-addon-bg: $body-bg;
$custom-control-gutter: .5rem;
$custom-control-spacer-x: 1.1rem;
$custom-control-indicator-bg: #ddd !default;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);
$custom-control-indicator-size: 1rem;

$custom-control-indicator-disabled-bg: $input-disabled-bg;
$custom-control-indicator-checked-color: $white;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg,
$input-btn-focus-box-shadow;

$custom-control-indicator-active-color: $white;
$custom-control-indicator-active-bg: lighten($primary, 35%);
$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"),
   "#",
   "%23"
);

$custom-checkbox-indicator-indeterminate-bg: $primary;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"),
   "#",
   "%23"
);
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"),
   "#",
   "%23"
);

$custom-select-padding-y: 0.375rem;
$custom-select-padding-x: 0.75rem;
$custom-select-line-height: $input-btn-line-height;
$custom-select-bg: $white;
$custom-select-indicator-color: #333;
$custom-select-indicator: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"),
   "#",
   "%23"
);

$custom-select-border-width: $input-btn-border-width;
$custom-select-focus-border-color: lighten($primary, 25%);
$custom-select-focus-width: $input-btn-focus-width;

$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, 0.075),
0 0 5px rgba($custom-select-focus-border-color, 0.5);

$custom-select-font-size-sm: 75%;
$custom-select-font-size-lg: 125%;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
$input-btn-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-btn-focus-width; // For focus box shadow issue in IE/Edge
$custom-file-focus-box-shadow: 0 0 0 0.075rem $white,
0 0 0 0.2rem $primary;
$custom-file-padding-y: $input-btn-padding-y;
$custom-file-padding-x: $input-btn-padding-x;
$custom-file-line-height: $input-btn-line-height;
$custom-file-border-width: $input-btn-border-width;

//remove icon for is-invalid in form input
$enable-validation-icons: false;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-link-hover-bg: $body-bg;
$dropdown-divider-bg: $primary-1;
$dropdown-box-shadow: 0 5px 15px rgba(9, 14, 18, 0.07);
$dropdown-link-color: $blue-50;

// Navs
$nav-link-padding-y: 0.5rem !default;
$nav-tabs-border-color: #ddd !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent;
$nav-divider-margin-y: ($spacer * 0.5);

// Navbar
$navbar-padding-y: ($spacer * 0.5);
$nav-link-height: ($font-size-base * $line-height-base + $nav-link-padding-y * 2);
$navbar-dark-color: rgba($white, 1);
$navbar-dark-hover-color: rgba($white, 0.9);
$navbar-dark-disabled-color: rgba($white, 0.5);
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
   "#",
   "%23"
);
$navbar-light-color: $gray-600;
$navbar-light-hover-color: $gray-300;
$navbar-light-active-color: $gray-800;
$navbar-light-disabled-color: $body-bg;
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
   "#",
   "%23"
);

$dropdown-divider-margin-y: 1rem;
$dropdown-border-width: 0;
$dropdown-box-shadow: 0 5px 15px rgba(9, 14, 18, 0.07);

// Pagination
$pagination-border-color: #ddd !default;
$pagination-hover-bg: $body-bg;
$pagination-hover-border-color: #ddd !default;
$pagination-disabled-border-color: #cfd8dc;
$pagination-disabled-color: #cfd8dc;

// Cards
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$card-border-color: rgba($black, 0.06);
$card-cap-bg: $white;

// Badges
$badge-font-size: 85%;
$badge-padding-y: 0.35em;

// Modals

// Padding applied to the modal body
$modal-header-border-color: $gray-100;
$modal-header-padding: 1rem 1.5rem;
$modal-inner-padding: 1.5rem;
$modal-header-border-color: $input-disabled-bg;
$modal-footer-border-color: $input-disabled-bg;

// Progress bars
$progress-font-size: ($font-size-base * 0.75);
$progress-bg: #eee;

// List group

$list-group-border-color: #e4e7ed;
$list-group-item-padding-y: 1.25rem;
$list-group-hover-bg: $body-bg;

// Image thumbnails
$thumbnail-border-color: #ddd !default;

// Breadcrumbs
$breadcrumb-padding-x: 0rem;
$breadcrumb-divider: "/";

// Carousel
//----------------- bottom both variables giving error so commented
$carousel-control-color: $white;
$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"),
   "#",
   "%23"
);
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"),
   "#",
   "%23"
);

$carousel-transition: transform 0.6s ease; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Code

$code-font-size: 90% !default;
$kbd-bg: $body-bg !default;

$pre-color: $gray-600 !default;

// Z-index
$zindex-fixed: 1038 !default;

// Timeline Vertical
$timeline-color:#dfe3e7;

// Dropsuite
$section-margin-top: 2.5rem;
$sub-section-margin-top: 2rem;

// Datepicker
$datepicker-day-width: 34px;