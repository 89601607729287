.manage-organization-page,
.manage-general-organization-page,
.manage-logins-organization-page,
.manage-subscriptions-organization-page,
.manage-features-organization-page,
.manage-credentials-organization-page,
.manage-journal-organization-page
{
  .card {
    margin-bottom: 0;

    & + .card {
      margin-top: .5rem;
    }

    /* Credentials page */
    .credentials-card{
      .no-credentials{
        padding: 6.25rem;
      }

      .important-notice-section{
        .text{
          .title{
            font-size: $font-size-sm;
            color: #0075E1;
          }

          .description{
            font-size: .75rem;
            color: #072A4A;
            line-height: 1.2rem;
            ul {
              padding-left: 1.88rem;
              li {
                font-size: .75rem;
              }
            }
          }
        }
      }

      .credentials-table{
        .smaller-font{
          font-size: .75rem;
          line-height: 1.35rem;
        }

        th.status{
          width: 20%;
        }

        th.action{
          width: 40%;
        }

        td.action{
          .information-text{
            display: inline-block;

            .status{
              font-weight: 600;
              color: $red-50;
              float: left;
              margin-right: 0.25rem;
            }

            .inactive{
              font-weight: 600;
              float: left;
              margin-right: 0.25rem;
            }

            .title{
              font-weight: 600;
              color: $red-50;
            }
          }

          .information-text:not(:first-of-type) {
            margin-top: .625rem;
          }

          .btn-reauthenticate{
            margin-top: .25rem;
            font-size: .750rem;
          }
        }
      }

      /* End of Credentials page */
    }
  }

  h2 {
    .badge {
      font-size: .75rem;
      margin-left: .5rem;
    }
  }

  .plan-icon {
    color: $blue-50;
    font-size: 2.5rem;
  }

  .plan-info {
    span {

      & + span {
        border-left: 1px solid $primary-3;
        margin-left: .5rem;
        padding-left: .5rem;
      }
    }
  }

  .card-subscription-action {
    height: 100%;
    min-height: 430px;
    position: relative;

    .card-body, .card-footer {
      background-color: transparent;
      padding: 1rem;
    }

    .card-footer {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
    }

    p {
      @extend .small;
    }

    .switchery {
      height: 16px;
      width: 28px;
    }

    .switchery > small {
      height: 14px;
      width: 14px;
    }

    .loading-wrapper {
      position: relative;

      .loading-toggle {
        background: #dcdcdc;
        position: absolute;
        z-index: 1;
        width: 28px;
        height: 16px;
        border-radius: 30px;
        top: 1px;
        right: -1px;

        img {
          &.right-position {
            position: absolute;
            top: 3px;
            left: 16px;
          }

        }
      }
    }
  }

  .table {
    font-size: .875rem;

    th, td {
      padding: 1rem 1rem;
    }
  }

  #journal-email{
    word-wrap: break-word;
  }

  .copy-button {
    color: $blue-50;
    font-size: .875rem;
    padding: 0;
    font-weight: $font-weight-bold;

    span {
      padding-left: .5rem;
    }
  }

  form#upgrade-org {
    .form-group {
      margin-bottom: .5rem;
    }
  }

  .disabled-switchery {
    opacity: 0.5;
  }

  .switchery {
    height: 22px;
    width: 40px;
  }

  .switchery > small {
    height: 20px;
    width: 20px;
  }

  .seats-summary {
    .accordion {
      cursor: pointer;
      width: 100%;
      border: none;
      text-align: left;
      outline: none;
      border-top: 1px solid #F2F4F6;
    }

    .arrow {
      float: right;
      color: $blue-50;
      font-size: 1.2rem;
      &:after {
        font-family: FontAwesome;
        display: inline-block;
        content: "\f107";
        transform: rotate(0);
			  transition: -webkit-transform 0.2s ease-in-out;
      }

      &.active:after {
        transform: rotate(180deg);
      }
    }

    .panel {
      padding: 0 18px;
      background-color: white;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      border-bottom: 1px solid #F2F4F6;

      span {
        display: inline-block;
        line-height: 2.5rem;
      }

      span:first-child {
        font-weight: $font-weight-bold;
        min-width: 35%;
      }

      span:last-of-type {
        max-width: 65%;
      }


    }

    .tooltips {
      margin-left: .5rem;
    }
  }
}

.nfr-info {
  .tooltips {
    margin-left: .5rem !important;
  }
}

.nfr-warning {
  color: $red-50 !important;
}

.update-subscription-confirmation {
  .table {
    margin: 1rem 1rem;
    width: 94%;

    td {
      border-top: none;
      padding: 0.75rem;
    }
  }
}

.private-chat-detail-confirmation {
  /* wrapper */
  box-sizing: border-box;

  background: #E3F0FC;
  /* blue/30 */

  border: 1px solid #8CC4F8;
  border-radius: 4px;

  ul {
    padding: 1rem 1rem 1rem 1.88rem;
    margin: 0px;
  }
}